export const advantages = [
  {
    icon: 'fas fa-users',
    title: 'An easy way to share your data',
    subtitle: 'Use advanced online visualisation to share your datasets.',
  },
  {
    icon: 'fas fa-user-lock',
    title: 'Public and private geomodels',
    subtitle:
      'Control who sees your datasets, or share with the rest of the world.',
  },
  {
    icon: 'fas fa-vr-cardboard',
    title: 'Web XR Enabled',
    subtitle:
      'Built with VR and XR capabilities. Use the latest technology to view your models.',
  },
  {
    icon: 'fas fa-thumbs-up',
    title: 'Intergrated with VRGS',
    subtitle:
      'Use VRGS to construct and interpret your models, then upload to the cloud.',
  },
];

export const mapData = [
  {
    location: {
      y: 52.981229,
      x: -3.142859,
      address: 'Trefor Rocks',
      url: '/tour3d-trefor-rocks',
      title: 'Trefor Rocks, Llangollen, Carboniferous ',
    },
  },
  {
    location: {
      y: 54.490867,
      x: -0.615802,
      address: 'Whitby',
      url: '/tour3d-whitby',
      title: 'Whitby, NE England, Jurassic',
    },
  },
  {
    location: {
      y: 54.117917,
      x: -0.082608,
      address: 'Flamborough Head',
      url: '/tour3d-flamborough',
      title: 'NE England, Cretaceous',
    },
  },
  {
    location: {
      y: 54.558108,
      x: -0.781888,
      address: 'Helmshore Textile Museum',
      url: '/tour3d-htmuseum',
      title: 'Helmshore Textile Museum',
    },
  },
  {
    location: {
      y: 54.558108,
      x: -0.781888,
      address: 'Lower Ormerods',
      url: '/tour3d-ormerods',
      title: 'Lower Ormerods, ',
    },
  },
];
