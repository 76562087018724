import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';

import Grid from '@mui/material/Grid/Grid';
import Typography from '@mui/material/Typography/Typography';
import Icon from 'components/atoms/Icon/Icon';
import SectionHeader from 'components/molecules/SectionHeader/SectionHeader';
import DescriptionListIcon from 'components/organisms/DescriptionListIcon/DescriptionListIcon';

const Advantages = (props) => {
  const { data, className, ...rest } = props;
  const theme = useTheme();

  return (
    <div className={className} {...rest}>
      <SectionHeader
        title={
          <span>
            <Typography component="span" variant="inherit" color="primary">
              Secure and Flexible{' '}
            </Typography>{' '}
            online storage and sharing of digital outcrop datasets
          </span>
        }
        subtitle="the online digital outcrop solution for Enterprise and Education"
        fadeUp
      />
      <Grid container spacing={4}>
        {data.map((item, index) => (
          <Grid key={index} item xs={12} sm={6} md={3} data-aos="fade-up">
            <DescriptionListIcon
              title={item.title}
              subtitle={item.subtitle}
              icon={
                <Icon
                  fontIconClass={item.icon}
                  size="medium"
                  fontIconColor={theme.palette.primary.main}
                />
              }
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

Advantages.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default Advantages;
