import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

import Grid from '@mui/material/Grid/Grid';
import Typography from '@mui/material/Typography/Typography';
import CountUpNumber from 'components/molecules/CountUpNumber/CountUpNumber';
import SectionHeader from 'components/molecules/SectionHeader/SectionHeader';
import HeroShaped from 'components/organisms/HeroShaped/HeroShaped';
import Map from 'components/organisms/Map/Map';

const useStyles = makeStyles((theme) => ({
  placementGrid: {
    display: 'flex',
  },
  placementGridItemMiddle: {
    margin: `0 ${theme.spacing(3)}`,
  },
  map: {
    zIndex: 9,
  },
}));

const MapHero = (props) => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  return (
    <div className={className} {...rest}>
      <HeroShaped
        leftSide={
          <Grid container spacing={1} data-aos="fade-up">
            <Grid item xs={12}>
              <SectionHeader
                label="locations"
                title={
                  <span>
                    <Typography
                      color="secondary"
                      variant="inherit"
                      component="span"
                    >
                      Help grow the community.{' '}
                    </Typography>
                    Flexible plans to suit all budgets and needs.
                  </span>
                }
                subtitle="From a basic free tier to full enterprise requirements the GeoTour3D cloud service will cater to all needs."
                align="left"
                fadeUp
                disableGutter
              />
            </Grid>
            <Grid item xs={12}>
              <div className={classes.placementGrid}>
                <div>
                  <CountUpNumber
                    end={1}
                    label="Countries"
                    textColor="secondary"
                  />
                </div>
                <div className={classes.placementGridItemMiddle}>
                  <CountUpNumber end={5} label="Models" textColor="secondary" />
                </div>
              </div>
            </Grid>
          </Grid>
        }
        rightSide={
          <Map
            center={[53.700446, -2.301442]}
            pins={data}
            className={classes.map}
            zoom={2}
          />
        }
      />
    </div>
  );
};

MapHero.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default MapHero;
