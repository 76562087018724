import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Divider } from '@mui/material';
import Section from 'components/organisms/Section/Section';
import SectionAlternate from 'components/organisms/SectionAlternate/SectionAlternate';
import { Hub, MapHero, Spaces, Advantages } from './components';

import { mapData, advantages } from './data';

const useStyles = makeStyles((theme) => ({
  appBarBottom: {
    top: 'auto',
    bottom: 0,
    background: 'transparent',
    boxShadow: 'none',
  },
  toolbarBottom: {
    width: '100%',
    margin: '0 auto',
    padding: theme.spacing(0, 2),
  },
  chatIconButton: {
    position: 'absolute',
    right: theme.spacing(3),
    left: 'auto',
    top: theme.spacing(-3),
    background: theme.palette.primary.main,
    width: 55,
    height: 55,
    boxShadow: `0 2px 10px 0 ${theme.palette.cardShadow}`,
    '&:hover': {
      background: theme.palette.primary.main,
    },
  },
  forumIcon: {
    color: 'white',
    width: 25,
    height: 25,
  },
  contactForm: {
    padding: theme.spacing(3, 2),
    maxWidth: 800,
    margin: '0 auto',
  },
}));

const GeoTour3D = () => {
  const classes = useStyles();

  // const [openBottombar, setOpenBottombar] = React.useState(false);

  // const handleBottombarOpen = () => {
  //   setOpenBottombar(true);
  // };

  // const handleBottombarClose = () => {
  //   setOpenBottombar(false);
  // };

  return (
    <div>
      <Section className={classes.sectionNoPaddingTop}>
        <Hub />
      </Section>
      <SectionAlternate>
        <Advantages data={advantages} />
      </SectionAlternate>
      <Divider />
      <MapHero data={mapData} />
      <SectionAlternate>
        <Spaces />
      </SectionAlternate>
      <Divider />
    </div>
  );
};

export default GeoTour3D;
